// export const  API_ENDPOINT = "http://192.168.0.117:8081/api/";
// export const  API_ENDPOINT = "http://192.168.0.110:8081/api/";
// export const API_ENDPOINT_IMG = "http://192.168.0.110:8081";
export const  API_ENDPOINT = "https://api.spectransyscore.com/api/";
export const  API_ENDPOINT_IMG = "https://api.spectransyscore.com";

export const API_TOKEN = "gb$rfjb89sEc3hGNKg9b16ajg0Kla3";
export const capitalizeText = (text) => {
  if (text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  } else {
    // Handle the case when text is undefined or null
    return "";
  }
};

