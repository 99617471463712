import React, { useState, useEffect, useRef, useContext } from "react";
import MasterTable from "../Components/MasterTable";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  Add,
  CloseOutlined,
  MoreVert,
  Close,
  ArrowBack,
  RefreshOutlined,
} from "@mui/icons-material";
import CDrawer from "../Components/Container/CusDrawer";
import { axiosGet, axiosPost, axiosPostForm } from "../lib/api";
import GridContainer from "../Components/Container/GridContainer";
import Cookies from "js-cookie";
import {
  Box,
  Menu,
  MenuItem,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  Select,
  Chip,
  IconButton,
  TextField,
  Drawer,
  Typography,
  Collapse,
  Badge,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
} from "@mui/material";
import CreateButton from "../Components/buttons/CreateButton";
import CInput from "../Components/Container/CInput";
import AutoHideAlert from "../Components/Container/AutoHideAlert";
import AlertDialog from "../Components/Container/AlertDialog";
import SearchFilter from "../Components/buttons/SearchFilter";
import FilterButton from "../Components/buttons/FilterButton";
import { capitalizeText } from "../lib/config";
import UserContext from "../UserContext";

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 160,
    },
  },
};

// Function to validate email using a regular expression
const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export default function Attendee() {
  const ACCESS_TOKEN = Cookies.get("token");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
  const [openActive, setOpenActive] = useState(false);
  const [openSts, setOpenSts] = useState(false);
  const [statusType, setStatusType] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [data, setData] = useState([]);
  const [isAll, setIsAll] = useState(false);
  const [sortField, setSortField] = useState("createdDate");
  const [sortOrder, setSortOrder] = useState("descending");
  const [limit, setLimit] = useState(15);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataCount, setdataCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [openDrawerType, setOpenDrawerType] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [singleData, setSingleData] = useState([]);
  const [picture, setPicture] = useState(null);
  const [pictureUrl, setPictureUrl] = useState(null);
  const [pictureName, setPictureName] = useState(null);
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [company, setCompany] = useState("");
  const [role, setRole] = useState("");
  const [industry, setIndustry] = useState("");
  const [AOI, setAOI] = useState("4");
  const [preference, setPreference] = useState("");
  const [preferenceChip, setPreferenceChip] = useState([]);
  const [linkedIn, setLinkedIn] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const fileInputRef = useRef(null);
  const [postError, setPostError] = useState([]);
  const [error, setError] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filtersList, setfiltersList] = useState(false);
  const [isIndusSelected, setIsIndusSelected] = useState(false);
  const [isRoleSelected, setIsRoleSelected] = useState(false);
  const [isStatusSelected, setIsStatusSelected] = useState(false);
  const [isAppStatusSelected, setIsAppStatusSelected] = useState(false);
  const [isWebStatusSelected, setIsWebStatusSelected] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [appStatusFilter, setAppStatusFilter] = useState("");
  const [webStatusFilter, setWebStatusFilter] = useState("");
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [rowBtn, setRowBtn] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const [multistatusValue, setMultistatusValue] = useState("");
  const [multiAppStsValue, setMultiAppStsValue] = useState("");
  const [openMultiStatus, setOpenMultistatus] = useState(false);
  const [openMultiApp, setOpenMultiApp] = useState(false);
  const [openMulitiDelete, setOpenMultiDelete] = useState(false);
  const open = Boolean(anchorEl);
  const areCheckboxesEnabled = true;
  const multiAppStatus = true;
  const [sizeError, setSizeError] = useState("");

  // Function to get selected row data
  const getSelectedRowsData = () => {
    return rows.filter((row) => rowSelectionModel.includes(row.id));
  };

  useEffect(() => {
    const selectedRowsData = getSelectedRowsData();
    const selectedDatauniqids = selectedRowsData.map((row) => row.fullData);
    const cusID = selectedDatauniqids.map((row) => row.dataUniqId);
    setSelectedId(cusID);
    if (cusID.length > 0) {
      setRowBtn(true);
    } else {
      setRowBtn(false);
    }
    // let cusId=selectedDatauniqids.filter((data)=>data._id)
  }, [rowSelectionModel]);

  const handleSortModelChange = (model) => {
    if (model.length > 0) {
      const { field, sort } = model[0];
      setSortField(field);
      setSortOrder(sort == "asc" ? "ascending" : "descending");
    } else {
      // Reset to default sorting if needed
      setSortField("createdDate");
      setSortOrder("descending");
    }
  };
  const maxSizeInMB = 10;
  const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // 10 MB in bytes

    if (file) {
      if (file.size > maxSizeInBytes) {
        setSizeError(
          `File size exceeds ${maxSizeInMB} MB. Please select a smaller file.`
        );
        setIsButtonDisabled(true);
        return;
      }
      setIsButtonDisabled(false);

      setSizeError("");
      setPicture(file);
      setPictureUrl(URL.createObjectURL(file));
      setPictureName(file.name);
    }
  };

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setPicture(file);
  //     setPictureUrl(URL.createObjectURL(file));
  //     setPictureName(file.name);
  //   }
  // };

  const resetFileInput = () => {
    setPicture(null);
    setPictureUrl(null);
    setPictureName("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handlePreference = (event) => {
    setPreference(event.target.value);
  };
  const handlePreferenceKey = (event) => {
    if (event.key === "Enter" && preference.trim() !== "") {
      setPreferenceChip([...preferenceChip, preference.trim()]);
      // setPreferenceChip((prevpreferenceChip) => [...prevpreferenceChip, AOI.trim()]);
      setPreference("");
    }
  };

  const handleDeleteChip = (chipToDelete) => {
    setPreferenceChip((prevPrefChip) =>
      prevPrefChip.filter((chip) => chip !== chipToDelete)
    );
  };

  // const validateURL = (url) => {
  //   const urlPattern = /^(https?:\/\/)?([\w\d-]+\.)+[\w\d]{2,}(\/[\w\d#-]+)*$/i;
  //   return urlPattern.test(url);
  // };
  const validateURL = (url) => {
    const regex =
      /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]+(\/[a-zA-Z0-9-]*)?(\?[^ ]+)?$/;
    return regex.test(url);
  };

  const handleUrlChange = (e) => {
    const value = e.target.value;
    setLinkedIn(value);

    if (value.length <= 1) {
      setError("");
    } else if (validateURL(value)) {
      setError("");
    } else {
      setError("Please enter a valid LinkedIn profile URL");
    }
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    if (validateEmail(newEmail)) {
      setEmailError("");
    } else {
      setEmailError("Please enter a valid email address.");
    }
  };
  // const handleMobileChange = (e) => {
  //   const value = e.target.value;
  //   // if (/^\d*$/.test(value)) {
  //   if (/^\d{0,10}$/.test(value)) {
  //     setMobile(value);
  //     if (value.length === 10) {
  //       setMobileError("");
  //       // }
  //     } else if (value.length > 4) {
  //       setMobileError("Invalid mobile number");
  //     }
  //   }
  // };
  const handleMobileChange = (e) => {
    const value = e.target.value;

    // Allow digits and the + character, ensuring at least 10 digits in total
    if (/^\+?\d*$/.test(value)) {
      setMobile(value);

      // Count digits ignoring the +
      const digitCount = (value.match(/\d/g) || []).length;

      if (digitCount >= 10 || digitCount <= 5) {
        setMobileError(""); // Valid mobile number
      } else {
        setMobileError("Mobile number must contain at least 10 digits");
      }
    } else {
      setMobileError("Invalid mobile number");
    }
  };
  // Function to update searchValue when input changes
  const handleSearchInputChange = (input) => {
    setPageNumber(1);
    setSearchValue(input);
  };

  const handlefilterBadgeVisible = () => {
    if (
      isStatusSelected ||
      isStatusSelected ||
      isAppStatusSelected ||
      isWebStatusSelected ||
      isRoleSelected ||
      isIndusSelected
    ) {
      // setFilterBadgeVisible(true);
      return true;
    } else {
      // setFilterBadgeVisible(false);
      return false;
    }
  };

  // Toggel filter component
  const HandleChangeFilter = () => {
    setfiltersList(!filtersList);
    // if (filtersList === true) {
    //   setIsIndusSelected(false);
    //   setIsRoleSelected(false);
    //   setIsStatusSelected(false);
    //   setIsAppStatusSelected(false);
    //   setIsWebStatusSelected(false);
    //   setAppStatusFilter(3);
    //   setStatusFilter(3);
    // }
  };

  const handleRefresh = () => {
    setSearchValue("");
    setIsIndusSelected(false);
    setIsRoleSelected(false);
    setIsStatusSelected(false);
    setIsAppStatusSelected(false);
    setIsWebStatusSelected(false);
    setAppStatusFilter("");
    setWebStatusFilter("");
    setStatusFilter("");
  };

  // Toggel Drawer
  const toggleDrawer = (newOpen, type) => () => {
    // type 1 : Create
    // type 2 : Edit
    setOpenDrawer(newOpen);
    setOpenDrawerType(type);

    handleCloseMenu();
    if (type === 1) {
      setCompany("");
      setPreferenceChip([]);
      setFName("");
      setLName("");
      setIndustry("");
      setRole("");
      setPicture("");
      setPictureUrl("");
      setLinkedIn("");
      setEmail("");
      setMobile("");
      setError("");
      setSizeError("");
      setEmailError("");
      setMobileError("");
      setAOI(4);
    } else {
      setCompany(singleData.company);
      setPreferenceChip(singleData.preference);
      setFName(singleData.firstName);
      setLName(singleData.lastName);
      setIndustry(singleData.industry);
      setRole(singleData.role);
      setPicture(singleData.photo);
      setPictureUrl(singleData.photo);
      setLinkedIn(singleData.linkedInUrl);
      setMobile(singleData.phoneNumber);
      setEmail(singleData.email);
      setAOI(singleData.areaOfInterest);
    }
  };

  const handleSaveClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    const fullData = item.fullData;
    setSingleData(fullData);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Open Status change warning box
  const handleAppStatus = (type) => {
    setOpenSts(true);
    handleCloseMenu();
    setStatusType(type);
  };
  // Open Status change warning box
  const handleStatusOpen = () => {
    setOpenActive(true);
    handleCloseMenu();
  };

  // Open Status change warning box
  const handleViewOpen = () => {
    setOpenViewDrawer(true);
    handleCloseMenu();
  };

  const handleDeleteOpen = () => {
    setOpenDel(true);
    handleCloseMenu();
  };
  const handleMultiDelete = () => {
    setOpenMultiDelete(true);
  };
  // open multi status waring
  const handleMultiStatus = (event) => {
    setOpenMultistatus(true);
    setMultistatusValue(event.target.value);
  };
  // open multi status waring
  const handleAppMultiStatus = (event) => {
    if (event.target.value === 0) {
      setOpenMultiApp(true);
      setMultiAppStsValue(event.target.value);
    }
    if (event.target.value === 1) {
      setSelectedId("");
      setMultistatusValue("");
      setRowSelectionModel("");
      setRowBtn(false);
      setMultiAppStsValue("");
    }
  };

  // Handler to log the page number on page change
  const handlePageChange = (model, newPage) => {
    let page = model.page + 1;
    let pageSize = model.pageSize;
    setPageNumber(page);
    setLimit(pageSize);
  };

  const handlePageSizeChange = (newPageSize) => {
    setLimit(newPageSize);
    setPageNumber(0);
  };

  const handleAppStatusType = (value) => {
    setPageNumber(1);
    setAppStatusFilter(value);
    setIsAppStatusSelected(true);
    // handlefilterBadgeVisible(true)
  };
  const handleWebStatusType = (value) => {
    setPageNumber(1);
    setWebStatusFilter(value);
    setIsWebStatusSelected(true);
    // handlefilterBadgeVisible(true)
  };
  const handleStatusType = (value) => {
    setPageNumber(1);
    setStatusFilter(value);
    setIsStatusSelected(true);
    // handlefilterBadgeVisible(true)
  };
  // Filter Functions End

  const fetchData = async () => {
    setIsLoading(true);
    axiosGet
      .get(
        `attendee/registeration/get?userToken=${ACCESS_TOKEN}&page=${pageNumber}&limit=${limit}&isAll=${isAll}&sortField=${sortField}&sortOrder=${sortOrder}&query=${searchValue}&status=${
          appStatusFilter === 3 ? "" : appStatusFilter
        }&activeStatus=${statusFilter == 3 ? "" : statusFilter}&regStatus=${
          webStatusFilter === 3 ? "" : webStatusFilter
        }`
      )
      // &activeStatus=${statusFilter == 3 ?"":statusFilter}
      .then((response) => {
        if (response.data.message === "Attendees retrieved successfully") {
          setData(response.data.data);
          setdataCount(response.data.totalItems);
          setPageNumber(pageNumber === 0 ? 1 : pageNumber);
          setPageCount(response.data.total_pages);
          setIsLoading(false);
          // setIsAlertVisible(true);
          // setAlertSeverity("success");
          // setAlertMessage("response.data.error");
        } else {
          setIsLoading(false);
          setPostError(response.data.error);
          setIsAlertVisible(true);
          setAlertSeverity("error");
          setAlertMessage("Something went wrong.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(true);
      });
  };

  useEffect(() => {
    fetchData();
  }, [
    pageNumber,
    limit,
    searchValue,
    statusFilter,
    webStatusFilter,
    appStatusFilter,
    sortOrder,
    sortField,
  ]);

  // Funtion for create new data or edit existing data
  const handleSubmit = () => {
    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
    setIsLoading(true);
    const formData = new FormData();
    formData.append("userToken", ACCESS_TOKEN);
    formData.append("firstName", fName);
    formData.append("lastName", lName);
    formData.append("company", company);
    formData.append("email", email);
    formData.append("phoneNumber", mobile);
    formData.append("role", role);
    formData.append("industry", industry);
    preferenceChip?.forEach((item) => {
      formData.append("preference", item);
    });
    formData.append("areaOfInterest", AOI);
    formData.append("linkedInUrl", linkedIn);
    if (openDrawerType == 1) {
      formData.append("photo", picture);
    } else if (openDrawerType == 2) {
      formData.append("dataUniqId", singleData?.dataUniqId);
      const photoValue =
        picture === singleData.photo ? singleData.existingPhotoPath : picture;

      if (typeof photoValue === "string") {
        formData.append("existingPhotoPath", photoValue);
      } else {
        formData.append("photo", photoValue);
      }
    }

    try {
      if (openDrawerType == 1) {
        axiosPostForm
          .post(`attendee/registeration/by/adminpanel`, formData)
          .then((response) => {
            // Handle the successful POST response here
            // if (response.data.action === "success") {
            // If response data action is 200, show the alert
            setIsAlertVisible(true);
            setAlertSeverity("success");
            setAlertMessage(response.data.message);
            // Cookies.remove("uuid");
            setOpenDrawer(false);
            setIsLoading(false);
            fetchData();
            // } else {
            //   setIsAlertVisible(true);
            //   setAlertSeverity("error");
            //   setIsLoading(false);
            //   setAlertMessage(response.data.message);
            //   setPostError(response.data.message);
            // }
          })
          .catch((error) => {
            // Handle POST errors here
            console.error("POST Error:", error);
            setIsLoading(false);
            setIsAlertVisible(true);
            setAlertSeverity("error");
            setAlertMessage(error?.response.data.error);
          });
      } else {
        axiosPostForm
          .put(`attendee/update`, formData)
          .then((response) => {
            // Handle the successful POST response here
            if (response.data.message === "Attendee updated successfully") {
              // If response data action is 200, show the alert
              setIsAlertVisible(true);
              setAlertSeverity("success");
              setAlertMessage(response.data.message);
              // Cookies.remove("uuid");
              setOpenDrawer(false);
              setIsLoading(false);
              fetchData();
            } else {
              setIsAlertVisible(true);
              setAlertSeverity("error");
              setIsLoading(false);
              setAlertMessage(response.error);
              // setPostError(response.data.message);
            }
          })
          .catch((error) => {
            // Handle POST errors here
            console.error("POST Error:", error);
            setIsAlertVisible(true);
            setAlertSeverity("error");
            setIsLoading(false);
            setAlertMessage(error?.response.data.error);
          });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // Funtion for change status of single data
  const handleActiveChange = () => {
    setIsLoading(true);
    const jsonData = {
      userToken: ACCESS_TOKEN,
      // dataUniqIds: [singleData.dataUniqId],
      dataUniqIds: selectedId.length > 0 ? selectedId : [singleData.dataUniqId],
      activeStatus: multistatusValue || singleData.activeStatus === 1 ? 0 : 1,
    };
    axiosPost
      .post(`attendee/change/activestatus`, jsonData)
      .then((response) => {
        if (response.data.message === "Active status updated successfully") {
          // setEffectToggle(!effectToggle);
          // setSelectedItems([]);
          // setActionData("");
          setAlertMessage("Updated successfully.");
          setIsAlertVisible(true);
          setAlertSeverity("success");
          handleCloseMenu();
          setIsLoading(false);
          fetchData();
          setSelectedId("");
          setMultistatusValue("");
          setRowSelectionModel("");
        }
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };

  // Funtion for delete single data
  const handleDelete = () => {
    setIsLoading(true);
    const jsonData = {
      userToken: ACCESS_TOKEN,
      dataUniqIds: selectedId.length > 0 ? selectedId : [singleData.dataUniqId],
    };
    axiosPost
      .delete(`attendee/delete`, { data: jsonData })
      .then((response) => {
        if (response.data.message === "Attendees deleted successfully") {
          // setEffectToggle(!effectToggle);
          handleCloseMenu();
          setAlertMessage("Deleted successfully.");
          setIsAlertVisible(true);
          setAlertSeverity("success");
          setIsLoading(false);
          fetchData();
          setSelectedId("");
          setMultistatusValue("");
          setRowSelectionModel("");
        }
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };

  // Funtion for change status of single data
  const handleAppStatusChange = () => {
    setIsLoading(true);
    const jsonData = {
      userToken: ACCESS_TOKEN,
      dataUniqId: singleData.dataUniqId,
      status: singleData.status === 1 ? 0 : 1,
    };
    axiosPost
      .put(`attendee/registeration/update/status`, jsonData)
      .then((response) => {
        // setEffectToggle(!effectToggle);
        // setSelectedItems([]);
        // setActionData("");
        setAlertMessage("Updated successfully.");
        setIsAlertVisible(true);
        setAlertSeverity("success");
        handleCloseMenu();
        setIsLoading(false);
        fetchData();
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };

  // Funtion for change status of single data
  const handleBulkAppChange = () => {
    setIsLoading(true);
    const jsonData = {
      userToken: ACCESS_TOKEN,
      dataUniqIds: selectedId,
      status: singleData.status === 1 ? 0 : 1,
    };
    axiosPost
      .post(`attendee/bulk/approval`, jsonData)
      .then((response) => {
        // setEffectToggle(!effectToggle);
        // setSelectedItems([]);
        // setActionData("");
        setAlertMessage("Updated successfully.");
        setIsAlertVisible(true);
        setAlertSeverity("success");
        handleCloseMenu();
        setIsLoading(false);
        fetchData();
        setSelectedId("");
        setMultistatusValue("");
        setRowSelectionModel("");
        setMultiAppStsValue("");
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };

  const columns = [
    // {
    //   field: "id",
    //   headerName: "#",
    //   flex: 1,
    //   width: 60,
    //   maxWidth: 80,
    //   // disableColumnMenu: true,
    //   headerClassName: "super-app-theme--header",
    // },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "company",
      headerName: "Company",
      flex: 1,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      minWidth: 100,
      maxWidth: 200,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "industry",
      headerName: "Industry",
      flex: 1,
      maxWidth: 200,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "webSts",
      headerName: "Registered On",
      flex: 1,
      maxWidth: 200,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      sortable: false,
    },
    {
      field: "appStatus",
      headerName: "Approval Status",
      flex: 1,
      maxWidth: 200,
      minWidth: 100,
      disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        const status = params.value; // 'app' or 'rej'
        const style = {
          color:
            status === "Pending"
              ? "#FFA500"
              : status === "Approved"
              ? "#008000"
              : null,
        };

        return <span style={style}>{status}</span>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      maxWidth: 200,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        const status = params.value; // 'app' or 'rej'
        const style = {
          color:
            status === "Inactive"
              ? "#FFA500"
              : status === "Active"
              ? "#008000"
              : null,
        };

        return <span style={style}>{status}</span>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      // disableColumnMenu: true,
      flex: 1,
      maxWidth: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <GridActionsCellItem
          icon={<MoreVert />}
          label="Save"
          sx={{ position: "relative", color: "primary.main" }}
          onClick={(event) => handleSaveClick(event, params.row)}
        />
      ),
    },
  ];

  const rows = data?.map((item, index) => ({
    id: index + 1,
    firstName: capitalizeText(item.firstName),
    lastName: capitalizeText(item.lastName),
    company: capitalizeText(item.company),
    role: capitalizeText(item.role),
    industry: capitalizeText(item.industry),
    webSts:
      item.regStatus === 1
        ? "Website"
        : item.regStatus === 2
        ? "Admin Panel"
        : "---",
    appStatus:
      item.status === 0 ? "Pending" : item.status === 1 ? "Approved" : null,
    status:
      item.activeStatus === 0
        ? "Inactive"
        : item.activeStatus === 1
        ? "Active"
        : null,
    fullData: item,
  }));

  const title = "Attendee";
  const boxSx = {
    "& .super-app-theme--header": {
      backgroundColor: "#0088AB",
      color: "white",
      fontSize: "16px",
      fontWeight: "bold",
    },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
      {
        backgroundColor: "#0088AB",
      },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
      color: "white",
    },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root.Mui-checked": {
      color: "white",
    },
  };

  const FilterComponent = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        <IconButton onClick={handleRefresh} size="small">
          <RefreshOutlined />
        </IconButton>

        <FormControl size="small" sx={{ minWidth: "150px" }}>
          <InputLabel sx={{ fontSize: "14px" }} id="game">
            {"Registered in"}
          </InputLabel>
          <Select
            MenuProps={MenuProps}
            sx={{
              fontSize: "14px",
              height: "34px",
              bgcolor: isWebStatusSelected ? "#185aa617" : undefined,
            }}
            placeholder={"Status"}
            labelId="status"
            id="status"
            label={"Registered in"}
            value={webStatusFilter}
            onChange={(e) => handleWebStatusType(e.target.value)}
          >
            <MenuItem sx={{ fontSize: "14px" }} value={3}>
              All
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={1}>
              Website
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={2}>
              Admin panel
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: "150px" }}>
          <InputLabel sx={{ fontSize: "14px" }} id="game">
            {"Approval Status"}
          </InputLabel>
          <Select
            MenuProps={MenuProps}
            sx={{
              fontSize: "14px",
              height: "34px",
              bgcolor: isAppStatusSelected ? "#185aa617" : undefined,
            }}
            placeholder={"Status"}
            labelId="status"
            id="status"
            label={"Approval Status"}
            value={appStatusFilter}
            onChange={(e) => handleAppStatusType(e.target.value)}
          >
            <MenuItem sx={{ fontSize: "14px" }} value={3}>
              All
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={0}>
              Pending
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={1}>
              Approved
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: "150px" }}>
          <InputLabel sx={{ fontSize: "14px" }} id="game">
            {"Status"}
          </InputLabel>
          <Select
            MenuProps={MenuProps}
            sx={{
              fontSize: "14px",
              height: "34px",
              bgcolor: isStatusSelected ? "#185aa617" : undefined,
            }}
            placeholder={"Status"}
            labelId="status"
            id="status"
            label={"Status"}
            value={statusFilter}
            onChange={(e) => handleStatusType(e.target.value)}
          >
            <MenuItem sx={{ fontSize: "14px" }} value={3}>
              All
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={1}>
              Active
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={0}>
              Inactive
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
  };

  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <CreateButton
          heading={title}
          pagecount={dataCount}
          onAddClick={toggleDrawer(true, 1)}
        />
        <Box display={"flex"} alignItems={"center"} gap={1.5}>
          {" "}
          <SearchFilter
            onSearchButtonClick={handleSearchInputChange}
            searchValue={searchValue}
          />
          <Badge
            color="secondary"
            variant="dot"
            invisible={!handlefilterBadgeVisible()}
          >
            <FilterButton
              HandleChangeFilter={HandleChangeFilter}
              filtersList={filtersList}
            />
          </Badge>
        </Box>
      </Box>

      <Collapse in={filtersList} timeout="auto" unmountOnExit>
        <Box sx={{ display: "flex", justifyContent: "end", py: 0.5 }}>
          {FilterComponent()}
        </Box>
      </Collapse>
      <Box sx={{ overflow: "auto" }}>
        <MasterTable
          rows={rows}
          columns={columns}
          areCheckboxesEnabled={areCheckboxesEnabled}
          multiAppStatus={multiAppStatus}
          title={title}
          limit={limit}
          loading={isLoading}
          boxSx={boxSx}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
          rowCount={dataCount}
          paginationModel={{ pageNumber, limit }}
          handleMultiDelete={handleMultiDelete}
          handleMultiStatus={handleMultiStatus}
          multistatusValue={multistatusValue}
          setRowSelectionModel={setRowSelectionModel}
          rowBtn={rowBtn}
          rowSelectionModel={rowSelectionModel}
          handleAppMultiStatus={handleAppMultiStatus}
          multiAppStsValue={multiAppStsValue}
          handleSortModelChange={handleSortModelChange}
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={toggleDrawer(true, 2)} sx={{ width: 140 }}>
          Edit
        </MenuItem>
        <MenuItem onClick={handleViewOpen}>View</MenuItem>
        {singleData?.status !== 1 && (
          <MenuItem onClick={() => handleAppStatus(1)}>Approve</MenuItem>
        )}
        {/* {singleData?.status !== 2 && (
          <MenuItem onClick={() => handleAppStatus(2)}>Reject</MenuItem>
        )} */}
        <MenuItem onClick={handleStatusOpen}>
          Mark as {singleData.activeStatus === 0 ? "active" : "inactive"}
        </MenuItem>
        <MenuItem onClick={handleDeleteOpen}>Delete</MenuItem>
      </Menu>

      {/* main drawer  */}
      <CDrawer
        open={openDrawer}
        close={() => setOpenDrawer(false)}
        title={title}
        loading={isButtonDisabled}
        buttonName={openDrawerType === 1 ? "Create" : "Save"}
        openType={openDrawerType}
        onSave={handleSubmit}
      >
        {/* Content for CDrawer */}
        <GridContainer spacing={2}>
          <Grid item xs={12} md={3}>
            <Stack>
              {pictureUrl ? (
                <div
                  style={{
                    // width: "100%",
                    height: "130px",
                    border: "2px dashed #ccc",
                    borderRadius: "10px",
                    padding: "4px",
                    textAlign: "center",
                    position: "relative",
                    placeItems: "center",
                    overflow: "hidden",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                    src={pictureUrl}
                    alt={pictureName}
                  />
                  <IconButton
                    sx={{
                      padding: 0.5,
                      px: 1,
                      position: "absolute",
                      top: "4px",
                      right: "4px",
                      background: "#f44336",
                    }}
                    onClick={resetFileInput}
                  >
                    <CloseOutlined
                      sx={{ color: "#fff", width: 16, aspectRatio: 1 / 1 }}
                    />
                  </IconButton>
                </div>
              ) : (
                <>
                  <label htmlFor="image-upload">
                    <div
                      style={{
                        width: "100%",
                        height: "130px",
                        border: "2px dashed #ccc",
                        borderRadius: "10px",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      <Box
                        component="img"
                        sx={{
                          height: "100%",
                          width: 110,
                        }}
                        alt="Placeholder"
                        src="/images/upload.svg"
                      />
                    </div>
                    <input
                      id="image-upload"
                      type="file"
                      ref={fileInputRef}
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </label>
                  {sizeError ? (
                    <Typography variant="caption" color="red">
                      {sizeError}
                    </Typography>
                  ) : (
                    <Typography variant="caption" color="#4b5563">
                      Maximum image size is 10MB.
                    </Typography>
                  )}
                </>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12} md={9}>
            <Grid container columnSpacing={1}>
              <Grid item xs={12} md={6}>
                <CInput
                  label="First Name"
                  value={fName}
                  name="First Name"
                  onChange={(e) => setFName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CInput
                  label="Last Name"
                  value={lName}
                  name="Last Name"
                  onChange={(e) => setLName(e.target.value)}
                />{" "}
              </Grid>
              <Grid item xs={12} md={6}>
                <CInput
                  label="Industry Type"
                  value={industry}
                  name="Industry Type"
                  onChange={(e) => setIndustry(e.target.value)}
                />{" "}
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">
                    Industry Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={industry}
                    label="Industry"
                    required
                    onChange={(e) => setIndustry(e.target.value)}
                  >
                    <MenuItem disabled selected>
                      Select the industry
                    </MenuItem>
                    {industryFData?.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item._id}
                        sx={{ textTransform: "capitalize" }}
                      >
                        {item.industryName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item xs={12} md={6}>
                <CInput
                  label="Company"
                  value={company}
                  name="Company"
                  onChange={(e) => setCompany(e.target.value)}
                />{" "}
              </Grid>
              <Grid item xs={12} md={6}>
                <CInput
                  label="Job Role"
                  value={role}
                  name="Job Role"
                  onChange={(e) => setRole(e.target.value)}
                />{" "}
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={role}
                    label="Role"
                    required
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <MenuItem disabled selected>
                      select a role
                    </MenuItem>
                    {roleFData?.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item._id}
                        sx={{ textTransform: "capitalize" }}
                      >
                        {item.role}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item xs={12} md={6}>
                <CInput
                  label="LinkedIn URL"
                  value={linkedIn}
                  name="LinkedIn URL"
                  onChange={handleUrlChange}
                  // onChange={(e) => setLinkedIn(e.target.value)}
                />
                {error && (
                  <Typography sx={{ color: "red", fontSize: "12px", px: 1 }}>
                    {error}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <CInput
              label="Mobile"
              value={mobile}
              name="Mobile"
              // onChange={(e) => setMobile(e.target.value)}
              onChange={handleMobileChange}
            />
            {mobileError && (
              <Typography
                sx={{ fontSize: "12px", color: "#FF0000", px: 0.5, pb: 1 }}
              >
                {mobileError}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <CInput
              label="Email"
              value={email}
              name="Email"
              onChange={handleEmailChange}
              // onChange={(e) => setEmail(e.target.value)}
            />
            {emailError && (
              <Typography sx={{ color: "red", fontSize: "12px", px: 1 }}>
                {emailError}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Area of Interest</FormLabel>
              <RadioGroup
                row
                aria-label="area-of-interest"
                name="area-of-interest"
                value={AOI}
                onChange={(e) => setAOI(e.target.value)}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio size="small" />}
                  label="Conditional Monitoring"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio size="small" />}
                  label="Reliability"
                />
                <FormControlLabel
                  value="3"
                  control={<Radio size="small" />}
                  label="Both"
                />
                <FormControlLabel
                  value="4"
                  control={<Radio size="small" />}
                  label="None"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {/* <Grid item md={12} p={1.5} pr={0}>
            <TextField
              sx={{ height: "auto", pr: 0 }}
              label="Preference"
              value={preference}
              onChange={handlePreference}
              onKeyPress={handlePreferenceKey}
              fullWidth
              variant="outlined"
              InputLabelProps={{ style: { top: "-3px" } }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => handlePreferenceKey({ key: "Enter" })}
                  >
                    <Add />
                  </IconButton>
                ),
              }}
            />
            <Typography sx={{ color: "blue", fontSize: "12px", px: 1 }}>
              Press enter to add
            </Typography>
            <div style={{ marginTop: "10px" }}>
              {preferenceChip?.map((chip, index) => (
                <Chip
                  key={index}
                  label={chip}
                  onDelete={() => handleDeleteChip(chip)}
                  deleteIcon={<Close />}
                  style={{
                    margin: "5px",
                    borderRadius: 6,
                    textTransform: "capitalize",
                  }}
                />
              ))}
            </div>
          </Grid> */}
          <Grid item md={12} p={1.5} pr={0}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                width: "100%",
                borderRadius: "6px",
                border: "1px solid  #e0e0e0",
              }}
            >
              <TextField
                size="small"
                sx={{
                  height: "auto",
                  flexGrow: 1,
                  pr: 0,
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove the outline
                  },
                  "& .MuiOutlinedInput-root": {
                    boxShadow: "none", // Remove any shadow effect
                  },
                }}
                label="Preference"
                value={preference}
                onChange={handlePreference}
                onKeyPress={handlePreferenceKey}
                variant="outlined" // Keep this for spacing
                InputLabelProps={{ sx: { background: "#fff" } }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => handlePreferenceKey({ key: "Enter" })}
                    >
                      <Add />
                    </IconButton>
                  ),
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginLeft: "8px", // Space between TextField and chips
                }}
              >
                {preferenceChip?.map((chip, index) => (
                  <Chip
                    key={index}
                    label={chip}
                    onDelete={() => handleDeleteChip(chip)}
                    deleteIcon={<Close />}
                    sx={{
                      margin: "2px",
                      borderRadius: "6px",
                      textTransform: "capitalize",
                    }}
                  />
                ))}
              </Box>
            </Box>
            <Typography sx={{ color: "#4b5563", fontSize: "12px", px: 1 }}>
              Press enter to add
            </Typography>
          </Grid>
        </GridContainer>
      </CDrawer>
      {/* main drawer end  */}

      {/* view drawer  */}
      <Drawer
        anchor={"right"}
        open={openViewDrawer}
        onClose={() => setOpenViewDrawer(false)}
        title={title}
        sx={{ width: "400px" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            py: 1,
            borderBottom: "1px solid #3636363b",
          }}
        >
          <IconButton onClick={() => setOpenViewDrawer(false)} size="small">
            <ArrowBack style={{ color: "black" }} />
          </IconButton>
          <Typography
            variant="h6"
            className="nunito_font"
            style={{
              fontSize: "18px",
              fontWeight: 700,
              color: "#185AA6",
            }}
          >
            View {title}
          </Typography>
        </Box>
        <Box sx={{ width: 400, p: 2 }}>
          <GridContainer>
            <Grid item xs={12} display={"flex"} justifyContent={"center"}>
              {singleData.photo ? (
                <img
                  src={singleData.photo}
                  alt=""
                  style={{ height: 120, borderRadius: "20px" }}
                />
              ) : (
                "No image..."
              )}
            </Grid>
            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>First Name</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
                textTransform={"capitalize"}
              >
                {singleData.firstName}
              </Typography>
            </Grid>
            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>Last Name</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
                textTransform={"capitalize"}
              >
                {singleData.lastName}
              </Typography>
            </Grid>
            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>Company</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
                textTransform={"capitalize"}
              >
                {singleData.company}
              </Typography>
            </Grid>
            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>Role</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
                textTransform={"capitalize"}
              >
                {singleData.role}
              </Typography>
            </Grid>
            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>Industry</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
                textTransform={"capitalize"}
              >
                {singleData.industry}
              </Typography>
            </Grid>
            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>Mobile</Typography>
              <a
                href={`tel:${singleData.phoneNumber}`}
                style={{ paddingLeft: 12, fontSize: "12px", color: "#000" }}
              >
                {singleData.phoneNumber}
              </a>
            </Grid>
            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>Email</Typography>
              <a
                href={`mailto:${singleData.email}`}
                style={{ paddingLeft: 12, fontSize: "12px", color: "#000" }}
              >
                {singleData.email}
              </a>
            </Grid>
            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>LinkedIn URL</Typography>
              <a
                title={singleData?.linkedInUrl}
                href={singleData?.linkedInUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  paddingLeft: 12,
                  fontSize: "12px",
                  color: "#000",
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {singleData.linkedInUrl}
              </a>
            </Grid>
            {singleData?.areaOfInterest ? (
              <Grid item xs={12} display={"flex"} flexDirection={"column"}>
                <Typography sx={{ color: "gray" }}>Area Of Interest</Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  sx={{ px: 1.5, fontSize: "12px" }}
                  textTransform={"capitalize"}
                >
                  {singleData?.areaOfInterest == "1"
                    ? "conditional monitoring"
                    : singleData?.areaOfInterest == "2"
                    ? "reliability"
                    : singleData?.areaOfInterest == "3"
                    ? "conditional monitoring & reliability"
                    : singleData?.areaOfInterest == "4"
                    ? "None"
                    : "---"}
                  {/* {singleData?.areaOfInterest?.map(item => `The field of interest is ${item}`)} */}
                </Typography>
              </Grid>
            ) : null}
            {singleData?.preference?.length > 0 ? (
              <Grid item xs={12} display={"flex"} flexDirection={"column"}>
                <Typography sx={{ color: "gray" }}>Preference</Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  sx={{ px: 1.5, fontSize: "12px" }}
                  textTransform={"capitalize"}
                >
                  {singleData?.preference?.join(", ")}
                </Typography>
              </Grid>
            ) : null}
          </GridContainer>
        </Box>
      </Drawer>
      {/* view drawer end  */}

      <AlertDialog
        onsubmit={handleActiveChange}
        open={openActive}
        handleClose={() => setOpenActive(false)}
        text={`Are you sure want to ${
          singleData.activeStatus == 1 ? "Active" : "Inactive"
        } ?`}
      ></AlertDialog>
      <AlertDialog
        onsubmit={handleActiveChange}
        open={openMultiStatus}
        handleClose={() => setOpenMultistatus(false)}
        text={`Are you sure want to change status of ${selectedId.length} items?`}
      ></AlertDialog>
      <AlertDialog
        onsubmit={handleBulkAppChange}
        open={openMultiApp}
        handleClose={() => setOpenMultiApp(false)}
        text={`Are you sure want to change approval status of ${selectedId.length} items?`}
      ></AlertDialog>
      <AlertDialog
        onsubmit={handleAppStatusChange}
        open={openSts}
        handleClose={() => setOpenSts(false)}
        text={`Are you sure want to ${
          statusType == 1 ? "Approve" : "Reject"
        } ?`}
      ></AlertDialog>
      <AlertDialog
        onsubmit={handleDelete}
        open={openDel}
        handleClose={() => setOpenDel(false)}
        text={"Are you sure want to delete ?"}
      ></AlertDialog>
      <AlertDialog
        onsubmit={handleDelete}
        open={openMulitiDelete}
        handleClose={() => setOpenMultiDelete(false)}
        text={`Are you sure want to delete ${selectedId.length} items?`}
      ></AlertDialog>
      {isAlertVisible && (
        <AutoHideAlert
          severity={alertSeverity}
          message={alertMessage}
          autoHideDuration={3000}
          open={isAlertVisible}
          onClose={() => setIsAlertVisible(false)}
        />
      )}
    </>
  );
}
